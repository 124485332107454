<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席2023国际资管科技创投大会
              </p>
              <div class="fst-italic mb-2">2023年4月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >资管大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/34/01-资管大会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    4月25日，由上海资产管理协会、智能投研技术联盟（ITL）和陆家嘴金融城发展局、陆家嘴金融科技协会共同主办的“2023国际资管科技创业者与投资者大会”在陆家嘴金融科技产业园举行。非凸科技作为ITL核心成员机构受邀参加项目展示活动，并与在场券商、私募基金、公募基金、创投机构等资管科技领域专业人士展开了面对面交流。
                  </p>
                  <p>
                    在主办方代表发表致辞后，来自10家金融科技企业的代表人围绕本企业“产品功能的技术实现”，对技术性能、技术路径、应用案例、研发实力等进行了展示和介绍。非凸科技商务总监朱家辉出席了此次活动，并分享了非凸算法产品的技术实现。非凸科技结合机器学习、深度学习等人工智能技术来获取稳健的超额收益，并基于Rust语言来保障算法交易系统的安全与稳定，以及通过持续迭代算力建设来实现交易执行的更高要求，从而为金融机构提供优质的算法交易解决方案。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/34/02-路演现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    此次，非凸科技在数智投资领域的技术展示，获得了广泛关注与好评，并与参会机构展开了深入交流，达成了后续合作意向。多位路演嘉宾表示，通过此次项目展示与对接活动，能够接触到更多金融机构领域的技术负责人和业务负责人，了解到金融机构对数智投资领域不同纬度的技术需求，与此同时，能够与金融机构进行面对面的交流学习和探讨合作，加强了行业、企业间的互动联络，受益颇多。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/34/03-现场合照.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近期，大模型技术的不断成熟和迭代，为资产管理机构运用前沿技术开展投研工作提供了更为广阔的空间。未来，非凸科技也将持续使用先进人工智能技术探索前沿算法，推动交易算法与智能交易应用产业链的深度协作，加速人工智能在金融科技场景的应用拓展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News34",
};
</script>

<style></style>
